<template>
  <div v-if="dataCont" style="min-height: 100%; background-color: #f7f7f7; min-width: 900rem; padding-bottom: 60rem">
    <div class="card" style="position: relative; padding-bottom: 40rem">
      <img class="bg" src="../../../../assets/img/fresh-registration-details-bg.png" alt=""/>
      <div class="title">报名基础信息</div>
      <div class="flex pl-24">
        <div class="box1">
          <div v-for="(item,index) in boxConfig_1" class="flex" :key="'config1-'+index">
            <div v-for="c_item in item" class="mt-24 cell" :key="'config1-c-'+c_item.prop">
              <span class="span1">{{ c_item.label }}</span>
              <span class="span2">
                <template v-if="c_item.label === '学生身份证号' || c_item.label === '监护人身份证号'">
                  <div v-if="c_item.label === '学生身份证号' && dataCont.info[c_item.prop]" class="flex flex-align">
                    {{ isShowIdCard_1 ? dataCont.info[c_item.prop] : isShowIdCard(dataCont.info[c_item.prop]) }}
                    <img v-if="isShowIdCard_1" :src="$OssAddress + 'yanjing.png'" alt=""
                         style="width: 24rem; margin-left: 12rem" @click="isShowIdCard_1 = false"/>
                    <img v-else :src="$OssAddress + 'yingcang.png'" alt="" style="width: 24rem; margin-left: 12rem"
                         @click="isShowIdCard_1 = true"/>
                  </div>
                  <div v-if="c_item.label === '监护人身份证号' && dataCont.info[c_item.prop]" class="flex flex-align">
                    {{ isShowIdCard_2 ? dataCont.info[c_item.prop] : isShowIdCard(dataCont.info[c_item.prop]) }}
                    <img v-if="isShowIdCard_2" :src="$OssAddress + 'yanjing.png'" alt=""
                         style="width: 24rem; margin-left: 12rem" @click="isShowIdCard_2 = false"/>
                    <img v-else :src="$OssAddress + 'yingcang.png'" alt="" style="width: 24rem; margin-left: 12rem"
                         @click="isShowIdCard_2 = true"/>
                  </div>
                </template>
                <template v-else>
                  {{ dataCont.info[c_item.prop] }}
                </template>
              </span>
            </div>
          </div>
        </div>
        <div class="flex1" style="text-align: center; padding: 0 20rem">
          <el-image :fit="'contain'" :preview-src-list="[]" :src="dataCont.info.student_document_image"
                    style="width: 125rem; height: 174rem; border-radius: 20rem">
            <div slot="error" class="image-slot">
              <img alt="" src="../../../../assets/img/default-header.png"/>
            </div>
          </el-image>
        </div>
      </div>
    </div>

    <div class="card" style="position: relative; padding-bottom: 40rem">
      <div class="title">学生个人信息</div>
      <div class="pl-24 box2">
        <div v-for="(item,index) in boxConfig_2" class="flex" :key="'config2-'+index">
          <div v-for="c_item in item" class="mt-24 cell" :key="'config2-c-'+c_item.prop">
            <span class="span1">{{ c_item.label }}</span>
            <span class="span2">{{ dataCont.student[c_item.prop] }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="position: relative; padding-bottom: 20rem">
      <div class="title">初中学习概况</div>
      <p class="title-2">初中毕业情况</p>
      <div class="pl-30 box3">
        <div class="flex flex-wrap">
          <div class="mt-24 cell">
            <span class="span1">初中毕业时间</span><span
              class="span2">{{ dataCont.junior_high_school.student_attend_graduation }}</span>
          </div>
          <div class="mt-24 cell">
            <span class="span1">初中毕业学校</span><span
              class="span2">{{ dataCont.junior_high_school.student_attend_graduation_school }}</span>
          </div>
          <div class="mt-24 cell">
            <span class="span1">初中毕业班级</span><span
              class="span2">{{ dataCont.junior_high_school.student_attend_graduation_class }}</span>
          </div>
          <div class="mt-24 cell">
            <span class="span1">初中是否留级</span><span
              class="span2">{{ dataCont.junior_high_school.student_attend_repetition_text }}</span>
          </div>
          <div class="mt-24 cell">
            <span class="span1">初中是否休学</span><span
              class="span2">{{ dataCont.junior_high_school.student_attend_suspend_text }}</span>
          </div>
          <div class="mt-24 cell">
            <span class="span1">中考所在区域</span><span class="span2">{{ dataCont.junior_high_school.senior }}</span>
          </div>
          <div class="mt-24 cell" v-if="dataCont.junior_high_school.senior_city == '510100'">
            <span class="span1">是否指标到校</span><span
              class="span2">{{ dataCont.junior_high_school.is_indicator_school == 1 ? '是' : '否' }}</span>
          </div>
          <div class="mt-24 cell" v-if="dataCont.junior_high_school.senior_city == '510100'">
            <span class="span1">升学区域</span><span class="span2">{{ dataCont.junior_high_school.go_school }}</span>
          </div>
          <template v-if="dataCont.junior_high_school.is_indicator_school != 1">
            <div class="mt-24 cell">
              <span class="span1">中考报名号</span><span class="span2">{{
                dataCont.junior_high_school.application_no
              }}</span>
            </div>
            <div class="mt-24 cell">
              <span class="span1">中考准考证号</span><span class="span2">{{
                dataCont.junior_high_school.admission_ticket_no
              }}</span>
            </div>
          </template>
        </div>
      </div>
      <p class="title-2" style="margin-top: 50rem; padding-bottom: 20rem">
        {{ dataCont.junior_high_school.is_indicator_school == 1 ? '折算后考试成绩' : '中考成绩' }}
      </p>
      <div class="pl-30" v-if="dataCont.junior_high_school.exam_results">
        <el-table
            :stripe="false"
            :data="dataCont.junior_high_school.exam_results.list"
            :header-cell-style="{
            background: '#F0F7FF'
          }"
            style="width: 100%"
        >
          <el-table-column v-for="item in dataCont.junior_high_school.exam_results.header" :label="item.label" :key="'exam_results-'+item.prop"
                           :prop="item.prop"/>
        </el-table>
      </div>
      <div style="padding: 30rem 0 0 50rem">
        <div style="display: inline-block; margin: 0 40rem 20rem 0" v-for="item in imgList"
             v-if="dataCont.junior_high_school[item.prop]" :key="'imgList-'+item.prop">
          <el-image style="width: 200rem; height: 200rem" :src="dataCont.junior_high_school[item.prop]"
                    :preview-src-list="imgPreview"></el-image>
          <p style="text-align: center; font-size: 14rem; color: rgba(0, 0, 0, 0.6); margin-top: 8rem">{{
              item.label
            }}</p>
        </div>
      </div>
    </div>

    <div class="card" style="position: relative; padding-bottom: 40rem">
      <div class="title">高中学习计划</div>
      <div class="pl-24 box4">
        <div v-for="(item,index) in boxConfig_4" class="flex" :key="'config4-'+index">
          <div v-for="c_item in item" class="mt-24 cell" :key="'config4-c-'+c_item.prop">
            <span class="span1">{{ c_item.label }}</span>
            <span class="span2">{{ dataCont.senior_high_school[c_item.prop] }}</span>
          </div>
        </div>
        <div class="flex">
          <div class="mt-24 cell">
            <span class="span1">就读途径</span>
            <span class="span2">{{ dataCont.senior_high_school.student_attend_road_text }}</span>
          </div>
          <div v-if="dataCont.senior_high_school.student_attend_road === 2" class="mt-24 cell">
            <span class="span1">高中就读学校</span>
            <span class="span2">{{ dataCont.senior_high_school.repetition_graduation_school }}</span>
          </div>
          <div v-if="dataCont.senior_high_school.student_attend_road === 2" class="mt-24 cell">
            <span class="span1">留级原因</span>
            <span class="span2">{{ dataCont.senior_high_school.repetition_reason }}</span>
          </div>
          <div class="mt-24 cell">
            <span class="span1">高中预选学科组合</span>
            <span class="span2">{{ dataCont.senior_high_school.preselection_subject_str }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="orderCont" class="card" style="position: relative; padding-bottom: 40rem">
      <div class="title">签约订单信息</div>
      <div class="pl-24 box5">
        <div class="flex flex-wrap">
          <div
              v-for="item in boxConfig_5"
              class="mt-24 cell"
              :key="'config5-'+item.prop"
              :style="{
              marginBottom: item.label === '入学协议编号' ? '20rem' : 0,
              minWidth: item.label === '支付订单号' ? '630rem' : '300rem'
            }"
          >
            <span class="span1">{{ item.label }}</span>
            <span class="span2">{{ orderCont.sign_order[item.prop] }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="orderCont" class="card" style="position: relative; padding-bottom: 40rem">
      <div class="title">入学收费信息</div>
      <div class="pl-24 box7">
        <div v-for="(item,index) in boxConfig_7" class="flex" :key="'config7-'+index">
          <div v-for="c_item in item" class="mt-24 cell" :key="'config7-c-'+c_item.prop">
<!--            <span class="span1" :style="{ width: c_item.width + 'px' }">{{ c_item.label }}</span>-->
            <span class="span1">{{ c_item.label }}</span>
            <span class="span2">{{ orderCont.admission_fees[c_item.prop] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="orderCont.refund && !Array.isArray(orderCont.refund)" class="card" style="position: relative; padding-bottom: 40rem">
      <div class="title">退费详情</div>
      <div class="pl-24 box8">
        <div v-for="(item,index) in boxConfig_8" class="flex" :key="'config8-'+index">
          <div v-for="c_item in item" class="mt-24 cell" :key="'config8-c-'+c_item.prop">
<!--            <span class="span1" :style="{ width: c_item.width + 'px' }">{{ c_item.label }}</span>-->
            <span class="span1">{{ c_item.label }}</span>
            <span class="span2">{{ orderCont.refund[c_item.prop] }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="position: relative; padding-bottom: 40rem">
      <div class="title">高中入学情况</div>
      <template v-if="false">
        <div class="pl-24 box6">
          <div v-for="(item,index) in boxConfig_6" class="flex" :key="'config6-'+index">
            <div v-for="c_item in item" class="mt-24 cell" :key ="'config6-c-'+c_item.prop">
              <span class="span1">{{ c_item.label }}</span>
              <span class="span2">{{ dataCont[c_item.prop] }}</span>
            </div>
          </div>
        </div>
        <div class="pl-30" style="margin-top: 30rem">
          <el-table
              :stripe="false"
              :data="highMark"
              :header-cell-style="{
              background: '#F0F7FF'
            }"
              style="width: 100%"
          >
            <el-table-column v-for="item in highMark" :label="item.label" prop="prop" :key="'highMark'+item.prop"/>
          </el-table>
        </div>
      </template>
      <template v-else>
        <t-result type="empty" :text="'当前学生未入学'"></t-result>
      </template>
    </div>

    <div v-if="orderCont" class="card" style="position: relative; padding-bottom: 40rem">
      <div class="title">其他说明</div>
      <div class="mt-24 cell">
        <span class="span1 mr-20" style="padding-left: 30rem">面试评价</span>
        <div class="content">
          {{ orderCont.rests.interview_assessment }}
        </div>
      </div>
      <div class="mt-24 cell">
        <span class="span1 mr-20" style="padding-left: 30rem">面试结果凭证截图</span>
        <div class="content flex">
          <el-image v-for="(item,index) in orderCont.rests.interview_assessment_image"
                    :key="'interview_assessment_image-'+index"
                    style="width: 146rem; height: 146rem;margin-right: 20rem"
                    :src="item" fit="contain"
                    :preview-src-list="orderCont.rests.interview_assessment_image"></el-image>
        </div>
      </div>
      <div class="mt-24 cell">
        <span class="span1 mr-20" style="padding-left: 30rem">是否产生特批</span>
        <span class="span2">{{ orderCont.rests.is_special_approval === 1 ? '是' : '否' }}</span>
      </div>
      <div class="pl-30" style="margin-top: 30rem">
        <el-table
            :stripe="false"
            :data="orderCont.rests.special_approval"
            :header-cell-style="{
            background: '#F0F7FF'
          }"
            style="width: 100%"
        >
          <el-table-column v-for="item in specialConfig" :label="item.label" :prop="item.prop" :key="'specialConfig-'+item.prop"/>
        </el-table>
      </div>
      <div class="mt-24 cell">
        <span class="span1 mr-20" style="padding-left: 30rem">备注</span>
      </div>
      <div class="pl-30" style="margin-top: 30rem">
        <el-table
            :stripe="false"
            :data="remarkTable"
            :header-cell-style="{
            background: '#F0F7FF'
          }"
            style="width: 100%"
        >
          <el-table-column v-for="item in remarkConfig" :label="item.label" :prop="item.prop" :width="item.width" :key="'remarkConfig-'+item.prop"/>
          <el-table-column label="操作" prop="" :width="120">
            <template slot-scope="scope">
              <a style="color: #3491fa" href="javascript:" @click="remarkHandleDetails(scope.row)">查看</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--  查看协议  -->
    <el-dialog :visible.sync="agreementVisible" title="查看协议" width="800rem" custom-class="agreement-box">
      <div v-if="agreementList.length">
        <a v-for="item in agreementList" :href="item.other_url" target="_blank" :key="'agreement-'+item.other_url"
           style="display: block; margin-bottom: 20rem">{{ item.service_name }}</a>
      </div>
      <t-result v-else type="empty"></t-result>
    </el-dialog>

    <!--  添加备注  -->
    <el-dialog :visible.sync="remarkVisible" title="添加备注" width="850rem" custom-class="remark-box" @close="remarkClose">
      <div>
        <p style="color: #333; margin: 0 0 12rem 0">备注说明</p>
        <el-input resize="none" type="textarea" v-model="remark" placeholder="请输入" rows="8" maxlength="100"
                  show-word-limit></el-input>
      </div>

      <div>
        <p style="color: #333; margin: 20rem 0 12rem 0">招生过程截图（非必填）（最多上传10张图片）</p>
        <input type="file" ref="imgInput" multiple accept="image/png, image/jpeg, image/jpg" v-show="false"
               @change="addImg"/>
        <el-button class="replace-img" style="margin-bottom: 12rem" @click="btnClick">点击上传</el-button>
        <el-upload
            accept="image/png, image/jpeg, image/jpg"
            :before-upload="imgUploadBefore"
            :headers="headers"
            :http-request="uploadImg"
            multiple
            :limit="10"
            :file-list="fileList"
            :on-remove="imgUploadRemove"
            action="http://www.baidu.com"
            list-type="picture-card"
            name="image"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <!--        <el-dialog :visible="dialogVisible" :append-to-body="true" @close="dialogVisible = false">-->
        <!--          <img :src="dialogImageUrl" alt="" width="100%">-->
        <!--        </el-dialog>-->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="remarkHandle" :loading="fullscreenLoading">保存</el-button>
      </span>
    </el-dialog>

    <!--  查看备注  -->
    <el-dialog :visible.sync="remarkVisibleShow" title="查看备注" width="850rem" custom-class="remark-box"
               @close="remarkVisibleShow = false">
      <div class="flex">
        <p style="color: #333; margin: 0 20rem 12rem 0; min-width: 74rem">备注说明</p>
        <p>{{ remark_show }}</p>
      </div>

      <div v-if="remark_img.length">
        <p style="color: #333; margin: 20rem 20rem 12rem 0">招生过程截图</p>
        <el-image v-for="(item,index) in remark_img" style="width: 146rem; height: 146rem; margin-right: 4rem" :src="item" :key="'remark_img-'+index"
                  :preview-src-list="remark_img"></el-image>
      </div>

      <div v-else class="flex">
        <p style="color: #333; margin: 20rem 20rem 12rem 0">招生过程截图</p>
        <p style="line-height: 59rem">无</p>
      </div>
    </el-dialog>

    <h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList"
              @close="historyDialogShow = false"></h-dialog>

    <el-button style="margin-top: 40rem; margin-right: 30rem" @click="$router.back()">返回</el-button>
    <el-button type="primary" style="margin-top: 40rem; margin-right: 30rem" @click="agreementHandle">查看协议</el-button>
    <el-button type="primary" style="margin-top: 40rem; margin-right: 30rem" @click="remarkVisible = true">添加备注
    </el-button>
    <!--    <el-button type="primary" style="margin-top: 40rem; margin-right: 30rem" @click="$router.back()">发起特批</el-button>-->
    <el-button type="primary" style="margin-top: 40rem; margin-right: 30rem" @click="historyHandle">查看编辑记录</el-button>
    <el-button type="default" style="margin-top: 40rem; margin-right: 30rem" @click="onRefund" v-if="!!this.orderCont.refund_btn">{{ this.orderCont.refund_btn === 2?"查看退费详情":"发起退费" }}</el-button>
  </div>
</template>
<script>
import {hideIdCard} from '@/utils'

export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data() {
    return {
      fullscreenLoading: false,
      dataCont: '',
      orderCont: '',
      isShowIdCard_1: false,
      isShowIdCard_2: false,

      boxConfig_1: [
        [
          {label: '学生姓名', prop: 'student_name'},
          {label: '学生身份证号', prop: 'student_idcard'}
        ],
        [
          {label: '监护人姓名', prop: 'name'},
          {label: '监护人与学生关系', prop: 'relationship_name'},
          {label: '监护人身份证号', prop: 'parent_idcard'}
        ],
        [
          {label: '监护人电话', prop: 'phone'},
          {label: '监护人银行收款账户', prop: 'parent_bank'}
        ],
        [
          {label: '实际就读学校', prop: 'reality_school'},
          {label: '预报名咨询学校', prop: 'school'}
        ]
      ],

      boxConfig_2: [
        [
          {label: '学生性别', prop: 'sex'},
          {label: '学生实际生日', prop: 'birthday'},
          {label: '学生民族', prop: 'student_nation'}
        ],
        [
          {label: '重大疾病史', prop: 'physiology'},
          {label: '犯罪前科', prop: 'crime_text'},
          {label: '心理疾病', prop: 'psychology'},
          {label: '是否可招收', prop: 'recruit'}
        ],
        [
          {label: '身高（cm）', prop: 'student_height'},
          {label: '体重（kg）', prop: 'student_weight'},
          {label: '鞋子尺码', prop: 'student_shoe_size'},
          {label: '校服尺码', prop: 'preselection_uniform_size'}
        ],
        [
          {label: '期望寝室安排', prop: 'dorm_arrange'},
          {label: '学生户籍地', prop: 'student_register'},
          {label: '学生通信地址', prop: 'student_communication'},
          {label: '邮政编码', prop: 'student_postal_code'}
        ]
      ],

      boxConfig_4: [
        [
          {label: '高中外语语种', prop: 'student_high_languages_text'},
          {label: '高中预选科目', prop: 'preselection_subject_text'},
          {label: '高中学习类型', prop: 'study_type_text'},
          {label: '专业方向', prop: 'major'}
        ]
      ],

      boxConfig_5: [
        {label: '订单状态', prop: 'status_text'},
        {label: '支付状态', prop: 'pay_status_text'},
        {label: '签约状态', prop: 'sign_status_text'},
        {label: '支付成功时间', prop: 'pay_time'},
        {label: '入学签约时间', prop: 'formal_sign_time'},
        {label: '订单号', prop: 'order_number'},
        {label: '支付订单号', prop: 'payment_number'},
        {label: '入学协议编号', prop: 'agreement_sn'},
        {label: '正式报名学校顺序号', prop: 'formal_school_sn'},
        {label: '招生信息来源', prop: 'source'},
        {label: '预报名学校顺序号', prop: 'school_sn'},
        {label: '签约人', prop: 'customer_name'},
        {label: '签约人联系方式', prop: 'customer_phone'},
        {label: '是否参加初升高咨询服务', prop: 'application_type_text'},
        {label: '初升高咨询服务费用缴纳时间', prop: 'pay_succ_time'},
        {label: '初升高咨询服务协议签约时间', prop: 'sign_time'},
        {label: '初升高咨询服务咨询老师', prop: 'formal_sign_teacher'},
        {label: '入学报名招生老师', prop: 'sign_teacher'},
        {label: '当前客户归属人', prop: 'belong_teacher'}
      ],

      boxConfig_6: [
        [
          {label: '学号', prop: 'aaa'},
          {label: '行政班', prop: 'aaa'},
          {label: '班主任', prop: 'aaa'},
          {label: '班主任联系电话', prop: 'aaa'}
        ],
        [
          {label: '高中外语语种', prop: 'aaa'},
          {label: '高中学习方向', prop: 'aaa'},
          {label: '专业方向', prop: 'aaa'}
        ]
      ],

      boxConfig_7: [
        [
          {label: '收费档位', prop: 'rank_name'},
          {label: '是否享受优惠', prop: 'deduction_items_text'},
          {label: '高一应缴纳费用', prop: 'money_answer', width: '120'},
          {label: '高一实缴纳费用', prop: 'money_actual', width: '120'}
        ],
        [
          {label: '初升高咨询服务费', prop: 'pre', width: '134'},
          {label: '奖学金', prop: 'scholarship'},
          {label: '是否享受多胞胎入学优惠', prop: 'multiple', width: '172'}
          // {label: '特批优惠', prop: 'aaa'},
        ],
        [
          {label: '高二应缴纳费用', prop: 'senior_two_cost', width: '120'},
          {label: '高三应缴纳费用', prop: 'senior_three_cost', width: '120'}
        ]
      ],
      boxConfig_8: [
        [
          {label: '退费金额', prop: 'money'},
          {label: '退费时间', prop: 'refund_time'},
          {label: '退费状态', prop: 'refund_status_text'},
          {label: '退费方式', prop: 'refund_type_text'}
        ],
        [
          {label: '退费办理人', prop: 'refund_transactors_name'},
          {label: '退费发起人', prop: 'creater_name'},
          {label: '发起时间', prop: 'created_at'}
        ],
      ],

      highMark: [
        {label: '选科', prop: 'aaa'},
        {label: '语文', prop: 'aaa'},
        {label: '区域普高线', prop: 'aaa'},
        {label: '区域普高线', prop: 'aaa'},
        {label: '区域普高线', prop: 'aaa'},
        {label: '区域普高线', prop: 'aaa'},
        {label: '区域普高线', prop: 'aaa'},
        {label: '区域普高线', prop: 'aaa'}
      ],

      // imgList: [],
      imgPreview: [],

      remarkConfig: [
        {label: '备注内容', prop: 'content'},
        {label: '创建时间', prop: 'created_at', width: 400},
        {label: '创建人', prop: 'creator', width: 200}
      ],
      remarkTable: [],

      specialConfig: [
        {label: '特批类型', prop: 'type_text'},
        {label: '特批发起时间', prop: 'created_at'},
        {label: '发起人', prop: 'creator'}
      ],

      agreementVisible: false,
      agreementList: [],

      remarkVisible: false,
      remarkVisibleShow: false,
      remark: '',
      remark_show: '',
      remark_img: [],

      historyDialogShow: false,
      historyList: [],

      headers: {
        authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
      },
      //上传图片相关
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      errorMessage: ''
      // 退费相关
      ,isRefund:true, // 是否退费
    }
  },
  mounted() {
    this.getData()
    this.getOrder()
    this.getRemark()
  },
  computed: {
    imgList() {
      return [
        {label: '成都一诊成绩截图', prop: 'chengdu_one_diagnosis_image'},
        {label: '成都二诊成绩截图', prop: 'chengdu_two_diagnosis_image'},
        {label: '中考成绩截图', prop: 'score_image'},
        {label: '中考准考证照片', prop: 'admission_ticket_image'},
        {label: '户口本业主页面照片', prop: 'master_book_image'},
        {label: '户口本学生页面照片', prop: 'self_book_image'},
        {label: '身份证正面照片', prop: 'front_card_image'},
        {label: '身份证反面照片', prop: 'opposite_card_image'},
        {label: '升学区域截图', prop: 'go_school_image'},
      ]
    }
  },
  methods: {
    isShowIdCard(val) {
      return hideIdCard(val)
    },
    getData() {
      this.imgPreview = []
      this.$_register('api/recruit/fresh-graduate/details?id=' + this.$route.query.id).then(res => {
        this.dataCont = res.data.data
        let junior_high_school = res.data.data.junior_high_school
        let arr = [
          junior_high_school.chengdu_one_diagnosis_image,
          junior_high_school.chengdu_two_diagnosis_image,
          junior_high_school.score_image,
          junior_high_school.admission_ticket_image,
          junior_high_school.master_book_image,
          junior_high_school.self_book_image,
          junior_high_school.front_card_image,
          junior_high_school.opposite_card_image,
          junior_high_school.go_school_image,
        ];
        arr.forEach(item => {
          if(item) this.imgPreview.push(item)
        })
      })
    },

    getOrder() {
      this.$_register('api/recruit/fresh-graduate/order-cost?id=' + this.$route.query.id).then(res => {
        this.orderCont = res.data.data
      })
    },

    getRemark() {
      this.$_register('api/recruit/fresh-graduate/remark-list?id=' + this.$route.query.id).then(res => {
        this.remarkTable = res.data.data
      })
    },

    agreementHandle() {
      this.agreementVisible = true
      this.$_register('api/recruit/fresh-graduate/contract?info_id=' + this.dataCont.info.info_id).then(res => {
        this.agreementList = res.data.data || []
      })
    },

    remarkHandle() {
      if (!this.remark) {
        this.$message.error('请填写备注内容')
        return
      }
      this.fullscreenLoading = true
      let image = this.fileList.map(item => item.url).join(',')
      this.$_register
          .post('api/recruit/fresh-graduate/remark', {
            order_id: this.$route.query.id,
            content: this.remark,
            image
          })
          .then(res => {
            if (res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '添加成功'
              })
              this.getRemark()
              this.remarkVisible = false
            }
          })
          .finally(() => {
            this.fullscreenLoading = false
          })
    },

    remarkClose() {
      this.remarkVisible = false
      this.remark = ''
      this.fileList = []
    },

    historyHandle() {
      this.historyDialogShow = true
      this.$_register('api/recruit/common/get-opt-history?tbl_name=order_fresh_graduate&tbl_id=' + this.$route.query.id).then(res => {
        this.historyList = res.data.data.list || []
      })
    },

    remarkHandleDetails(row) {
      // console.log(row)
      this.remarkVisibleShow = true
      this.remark_show = row.content
      this.remark_img = row.image
    },

    //图片上传前的回调
    imgUploadBefore(file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg/png 格式!',
          duration: 2000
        })
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传图片大小不能超过 20MB!',
          duration: 2000
        })
      }
      return isJPG && isLt2M
    },

    //删除图片回调
    imgUploadRemove(file, fileList) {
      this.fileList = fileList
    },

    //点击图片时的回调
    imgUploadPreview(file) {
      this.dialogImageUrl = file[0].url
      this.dialogVisible = true
    },

    uploadImg(file) {
      return new Promise((resolve, reject) => {
        let boo = this.imgUploadBefore(file.file)
        if (boo) {
          const loading = this.$loading({
            lock: true,
            text: '上传中',
            customClass: 'img-upload-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$uploadToOSS_1(file.file, 3)
              .then(res => {
                resolve({
                  name: file.file.name,
                  url: res
                })
              })
              .finally(() => {
                loading.close()
              })
        }
      })
    },

    btnClick() {
      this.$refs.imgInput.value = ''
      this.$refs.imgInput.click()
    },

    addImg(e) {
      let list = e.target.files
      let count = 10 - this.fileList.length
      Object.keys(list).forEach(async (item, index) => {
        if (index < count) {
          let obj = await this.uploadImg({file: list[item]})
          this.fileList.push(obj)
        }
      })
    },

    // 退费按钮点击
    onRefund(){
      // 已经退费 跳转到
      if (this.orderCont.refund_btn === 2){
        this.$router.push("./refund-details?id="+this.$route.query.id+"&refund_offline_id="+this.orderCont.refund.refund_offline_id)
      }else{
        if (!!this.orderCont.refund_text){
          this.$message.warning(this.orderCont.refund_text)
        }else
          // 跳转到退费页
          this.$router.push("./refund?id="+this.$route.query.id)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .agreement-box .el-dialog__footer {
  padding: 0;
}

::v-deep .remark-box .el-dialog__footer {
  text-align: left;
  padding-left: 44rem;
}

::v-deep .el-upload--picture-card {
  display: none;
}

::v-deep .el-dialog.remark-box {
  width: 876rem !important;
}

span {
  display: inline-block;
}

.span1 {
  font-size: 14rem;
  color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
}

.span2 {
  font-size: 14rem;
  color: rgba(0, 0, 0, 0.6);
}

.pl-24 {
  padding-left: 24rem;
}

.pl-30 {
  padding-left: 34rem;
}

.mt-24 {
  margin-top: 24rem;
}

.mt-64 {
  margin-top: 64rem;
}

.mt-t {
  margin-top: 30rem;
}

.box1 {
  line-height: 26rem;

  & > div > div:nth-child(1) {
    min-width: 260rem;

    .span1 {
      width: 104rem;
    }
  }

  & > div > div:nth-child(2) {
    min-width: 430rem;

    .span1 {
      width: 160rem;
    }
  }

  & > div > div:nth-child(3) {
    min-width: 380rem;

    .span1 {
      width: 130rem;
    }
  }
}

.box2 {
  & > div > div {
    width: 23%;

    .span1 {
      width: 104rem;
    }
  }

  & > div > div:nth-child(3) {
    width: 31%;
  }
}

.box3,
.box6{
  & > div > div {
    width: 25%;

    .span1 {
      width: 104rem;
    }
  }
}

.box4 {
  & > div > div {
    width: 25%;

    .span1 {
      width: 120rem;
    }
  }

  & > div:nth-child(2) > div:nth-child(3) {
    width: 50%;
  }
}
.box8,
.box7 {
  & > div > div {
    width: 25%;

    .span1 {
      width: 140rem;
    }
  }
  & > div > div:nth-child(3) {
    .span1 {
      width: 180rem;
    }
  }
}

.box5 {
  .cell {
    margin-right: 30rem;
    min-width: 300rem;
  }

  .span1 {
    width: auto;
    margin-right: 20rem;
  }
}

.cell {
  display: flex;

  span:nth-child(2) {
    flex: 1;
    display: flex;
    align-items: flex-start;
  }
}

::v-deep th {
  font-weight: normal;
}

::v-deep .text-one .cell {
  white-space: nowrap;
}

::v-deep .image-slot {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

::v-deep .el-descriptions-item__content {
  color: #333;
}

.el-tabs__header span {
  width: 120rem;
  height: 130rem;
  color: #666;
  font-size: 16rem;
  padding-left: 30rem;

  i {
    padding: 0 10rem;
    border-radius: 6rem;
  }
}

.el-tabs__header .is-active i {
  background-color: #1d2088;
  color: #fff;
}

.el-tabs__header .is-active span {
  color: #333;
}

::v-deep .el-tabs__item {
  height: 120rem !important;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

.card {
  border-radius: 20rem 20rem 0 0;
  background-color: #fff;
  margin-bottom: 12rem;

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 220rem;
    height: 78rem;
  }

  .title {
    padding: 0 60rem 0 24rem;
    line-height: 60rem;
    font-size: 18rem;
    color: #333;
    font-weight: bold;
  }

  .title-2 {
    font-size: 16rem;
    padding-left: 24rem;
    margin: 26rem 0 10rem 0;
    color: rgba(0, 0, 0, 0.8);
  }
}

@media only screen and (max-width: 1640rem) {
  .box1 {
    & > div > div:nth-child(1) {
      min-width: 280rem;

      .span1 {
        width: 104rem;
      }
    }

    & > div > div:nth-child(2) {
      min-width: 290rem;

      .span1 {
        width: 160rem;
      }
    }

    & > div > div:nth-child(3) {
      min-width: 380rem;

      .span1 {
        width: 130rem;
      }
    }
  }
}

.zhu-detail-line {
  font-size: 14rem;

  .label {
    margin-right: 40rem;
    color: rgba(0, 0, 0, 0.8);
    flex-shrink: 0;
  }

  .content {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
